$primary-color: #354052;
$accent-color: #47bac1;
$accent-color-onHover: #1f8e94;
$textcolor: #6d6d6d;
$error-color: #f44455;
$label-color: #354052;
$app-background-color: #f7fbff;
$icon-color: #47bac1;
$active-background-color: #232d3b;
$active-background-color-onHover: #1e2733;
$highlight-color: #354052;
$highlight-color-onHover: #2b3747;
$link-color: #47bac1;
$link-color-onHover: #1f8e94;
$text-color: #212529;
$button-background-color: #47bac1;

$mat-form-field-label: slategray;
$mat-form-field-ripple: rgb(141, 150, 158);