@use '@angular/material' as mat;

@include mat.core();
@import "src/app/shared/style-variables";
@import "material-icons/iconfont/material-icons.scss";

body,
html {
  height: 100%;
}

body {
  color: $textcolor;
  font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}


/* '''''''''''''''''''''''''HTML Tag style''''''''''''''''''''''''''''''''''''''''''' */


/* '''''''''''''''''''''''''mat Table style''''''''''''''''''''''''''''''''''''''''''' */


/* '''''''''''''''''''''''''button style''''''''''''''''''''''''''''''''''''''''''' */
.submit-btn,
.update-btn {
  font-size: 16px !important;
  color: #fff;
  background-color: #47bac1;
}

.email-btn {
  color: rgb(71, 186, 193);
  font-size: 16px !important;
  margin-left: 5px !important;
}

.unresolve-btn {
  outline-color: transparent !important;
  margin-right: 10px !important;
  font-size: 16px !important;
  background-color: $error-color;
  color: #fff;
}

/* '''''''''''''''''''''''''Input field style''''''''''''''''''''''''''''''''''''''''''' */


/* '''''''''''''''''''''''''Div panel style''''''''''''''''''''''''''''''''''''''''''' */


/* '''''''''''''''''''''''''Error message style''''''''''''''''''''''''''''''''''''''''''' */

/* '''''''''''''''''''''''''Error message style''''''''''''''''''''''''''''''''''''''''''' */

/* '''''''''''''''''''''''''scrollbar style''''''''''''''''''''''''''''''''''''''''''' */
::-webkit-scrollbar {
  width: 10px !important;

  &:hover {
    cursor: pointer !important;
  }
}

::-webkit-scrollbar-track {
  cursor: pointer !important;
  background-color: rgb(243, 243, 243);
}

::-webkit-scrollbar-thumb {
  background: #cacaca;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 100px !important;

  &:hover {
    background: #47bac1;
    cursor: pointer !important;
  }
}

/* '''''''''''''''''''''''''alternate row background color style''''''''''''''''''''''''''''''''''''''''''' */
.row-odd {
  background-color: rgb(243, 243, 243);
  padding: 5px 0px !important;
}

.row-even {
  background-color: rgb(255, 255, 255);
}

.mat-mdc-header-cell {
  color: black !important;
  font-weight: bold;
  font-size: 16px;
  border-top: 1px solid rgba(0, 0, 0, .09) !important;
  border-bottom: 1px solid rgba(0, 0, 0, .09) !important;
}

.mat-mdc-header-row {
  color: black !important;
}

.mat-mdc-row:nth-child(even) {
  background-color: #fff;

}

tr.mat-mdc-row {
  height: 35px !important;
}

.mat-mdc-row:nth-child(odd) {
  background-color: rgba(0, 0, 0, .03);
}

.mat-mdc-cell {
  border-bottom: 1px solid rgba(0, 0, 0, .03) !important;
  border-top: 1px solid rgba(0, 0, 0, .03) !important;
  border-right: 1px solid rgba(0, 0, 0, .03) !important;
  border-left: 1px solid rgba(0, 0, 0, .05) !important;
  height: 20px !important;
  font-weight: 400;
}

.header-align-right {
  text-align: center !important;
  margin-left: 21px;
}

.mat-mdc-raised-button {
  &:focus {
    outline: none;
  }
}

.sm-input-field {
  width: 40% !important;
  margin-top: 5px;
}

.xs-input-field {
  width: 23% !important;
  margin-top: 10px;
  font-weight: 600 !important;
  font-size: 14px;
  text-transform: uppercase;
}

.div-panel {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid rgb(229, 233, 242);
}

.fixed-div-panel {

  margin-top: 50px;
  margin-bottom: 50px;
}

.error-row-div-panel {
  background-color: #fff;
  padding: 15px 20px 10px 30px;
  margin-bottom: 10px;
  border: 1px solid rgb(229, 233, 242);

  &:hover {
    cursor: pointer;
    background-color: rgb(253, 253, 253);
  }
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

li {
  padding: 8px;
  cursor: pointer;
  color: #adb5bd;
  outline: 0px;
  font-size: 14px;
}

label {
  font-size: 14px;
}

#customOverlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

.overlay-div,
.overlay-div-comparison-dialog {
  position: absolute;
  display: none;
  border-radius: 3px !important;
  top: 40%;
  left: 50%;
  height: 10% !important;
  // background-color: #4b5769 !important;
  box-shadow: 8px 4px 8px 0 rgba(0, 0, 0, 0.2), 8px 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 21474836;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.search-serialnumber {
  outline-color: rgb(185, 185, 185);
  border: 1px solid #adb5bd;
  padding-left: 5px;
  font-size: 16px;

  &:focus {
    border: 1px gray;
  }
}

.btn-primary {
  color: #ffffff;
  background-color: #47bac1 !important;
  border-color: #47bac1 !important;
  // width:100%!important;
}

.btn-primary:hover {
  background-color: rgb(15, 112, 117) !important;
}

.sticky-top {
  z-index: 2 !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #ffffff !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-shadow: none !important;
}

.mat-datepicker-toggle {
  color: $icon-color;
  font-size: 16px;
  box-shadow: none !important;
}

table {
  width: 100%;
}

.mat-mdc-table {
  overflow-y: scroll;
}

.errorTextColor {
  color: $error-color !important;
}


.h4 {
  color: $primary-color !important;
}

.total-fetch-row-btn {
  font-weight: 400;
  color: rgb(67, 88, 97) !important;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent !important;
  background-color: rgb(245, 245, 245) !important;
  padding: 0.375rem 0.75rem;
  font-size: 13px;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-shadow: none !important;
  cursor: none !important;

}

.mat-btn {
  background-color: $accent-color;
  color: #fff;
}

.delete-button {
  font-size: 14px;
  color: rgb(135, 138, 141) !important;
  outline-style: none !important;

  &:hover {
    cursor: pointer;
    color: rgb(31, 106, 177) !important;
  }
}

.save-button {
  margin-left: 3px;
  font-size: 14px;
  color: rgb(135, 138, 141) !important;

  &:hover {
    cursor: pointer;
    color: rgb(31, 106, 177) !important;
  }
}

.action-button {
  margin-left: 3px;
  font-size: 14px;
  color: rgb(135, 138, 141) !important;

  &:hover {
    cursor: pointer;
    color: rgb(31, 106, 177) !important;
  }
}

.sub-nav-link {
  float: right;
  margin-top: -15px;
}

.text-link {
  color: $link-color;
  text-decoration: underline !important;
  cursor: pointer;

  &:hover {
    color: $link-color-onHover;
  }
}

.flex {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

.flex>.item {
  width: 50%;
  padding: 10px;
}

.item:first-child {
  margin-right: 20px;
}

.form-control:focus {
  border-color: transparent;
  box-shadow: 0 0 0 0.1ex #ced4da;
}

.serialNumber-info {
  background-color: #fff;
  overflow-x: hidden;
}

.top-heading {
  font-size: medium;
  width: 100% !important;
  font-weight: 400;
  margin-bottom: -15px;
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, .55);
}

.bold-text {
  font-weight: 400;
  font-size: medium !important;
}

.close-icon {
  cursor: pointer;
  margin-right: 10px;
  color: #e4dee1;

  &:hover {
    color: #afafaf;
  }
}